<template>
  <div class="advance-search-warp">
    <div class="advance-search-top">
      <div class="data-type">
        <div class="data-type-title">数据类型</div>
        <div class="data-type-content">
          <el-tabs type="border-card" v-model="activeTab" @tab-click="switchTab">
            <el-tab-pane label="机构">
              <el-checkbox
                :indeterminate="isOrgIndeterminate"
                v-model="checkOrgAll"
                @change="handleCheckAllChange"
              >
                全选
              </el-checkbox>
              <div style="margin: 26px 0"></div>
              <el-checkbox-group v-model="checkedDataType" @change="handleOrgChecked">
                <el-checkbox v-for="(org, index) in orgDataType" :label="org" :key="index">
                  {{ org }}
                </el-checkbox>
              </el-checkbox-group>
            </el-tab-pane>

            <el-tab-pane label="医疗专业人士">
              <el-checkbox
                :indeterminate="isDocIndeterminate"
                v-model="checkDocAll"
                @change="handleCheckAllChange"
              >
                全选
              </el-checkbox>
              <div style="margin: 26px 0"></div>
              <el-checkbox-group v-model="checkedDataType" @change="handleDocChecked">
                <el-checkbox v-for="(org, index) in docDataType" :label="org" :key="index">
                  {{ org }}
                </el-checkbox>
              </el-checkbox-group>
            </el-tab-pane>

            <el-tab-pane label="产品">
              <el-checkbox
                :indeterminate="isDrugIndeterminate"
                v-model="checkDrugAll"
                @change="handleCheckAllChange"
              >
                全选
              </el-checkbox>
              <div style="margin: 26px 0"></div>
              <el-checkbox-group v-model="checkedDataType" @change="handleDrugChecked">
                <el-checkbox v-for="(org, index) in drugDataType" :label="org" :key="index">
                  {{ org }}
                </el-checkbox>
              </el-checkbox-group>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>

      <div class="search-conditions">
        <div class="search-conditions-title">搜索条件</div>
        <div class="search-conditions-content">
          <!-- 条件值 -->
          <div v-for="(obj, index) in searchCondition" :key="index" class="search-conditions-item">
            <!-- 字段名称 -->
            <el-select
              class="search-conditions-item-filedName"
              v-model="obj.property"
              placeholder="请选择字段"
              clearable
              filterable
              @change="
                val => {
                  handleOneCondition(val, index);
                }
              "
            >
              <el-option
                v-for="item in titleOpts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>

            <!-- 运算符 -->
            <el-select
              v-if="obj.type === 'select' || obj.type === 'cascader'"
              class="search-conditions-item-operator"
              v-model="obj.operator"
              placeholder="选择运算符"
              clearable
              filterable
              @change="
                val => {
                  handleTwoCondition(val, index);
                }
              "
            >
              <el-option
                v-for="item in operator2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <!-- type为input的情况 -->
            <el-select
              v-else-if="obj.type === 'input'"
              class="search-conditions-item-operator"
              v-model="obj.operator"
              clearable
              filterable
              placeholder="选择运算符"
              @change="
                val => {
                  handleTwoCondition(val, index);
                }
              "
            >
              <template
                v-if="
                  obj.property === 'name' ||
                    obj.property === 'product_name' ||
                    obj.property === 'sku_name'
                "
              >
                <el-option
                  v-for="item in operator0"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </template>
              <template v-else>
                <el-option
                  v-for="item in operator1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </template>
            </el-select>
            <!-- type为日期的情况 -->
            <el-select
              v-else-if="obj.type === 'date'"
              class="search-conditions-item-operator"
              v-model="obj.operator"
              clearable
              filterable
              placeholder="选择运算符"
              @change="
                val => {
                  handleTwoCondition(val, index);
                }
              "
            >
              <el-option
                v-for="item in operator3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>

            <!-- type为日期的情况 -->
            <el-select
              v-else-if="obj.type === 'number'"
              class="search-conditions-item-operator"
              v-model="obj.operator"
              clearable
              filterable
              placeholder="选择运算符"
              @change="
                val => {
                  handleTwoCondition(val, index);
                }
              "
            >
              <el-option
                v-for="item in operator4"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>

            <!-- 查询值 -->
            <el-select
              v-if="obj.type === 'select'"
              class="search-conditions-item-queryVal"
              :class="{ 'queryVal-muplt': obj.queryValue.length !== 0 }"
              v-model="obj.queryValue"
              placeholder="请选择查询值"
              multiple
              filterable
              @change="
                val => {
                  handleThreeCondition(val, index);
                }
              "
            >
              <el-option
                v-for="item in queryValOpts[obj.property]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-cascader
              v-else-if="obj.type === 'cascader'"
              class="search-conditions-item-queryVal"
              :class="{ 'queryVal-muplt': obj.queryValue.length !== 0 }"
              v-model="obj.queryValue"
              :ref="`cascaderMallCatergory-${index}`"
              :props="cascaderData[obj.property]"
              placeholder="请选择查询值"
              @change="
                val => {
                  hanldeCascaderVal(val, index);
                }
              "
            ></el-cascader>
            <el-date-picker
              v-else-if="obj.type === 'date'"
              class="search-conditions-item-queryVal datePickerVal"
              v-model="obj.queryValue"
              type="date"
              placeholder="选择日期"
              @change="
                val => {
                  hanldePickerVal(val, index);
                }
              "
            ></el-date-picker>
            <el-input-number
              v-else-if="obj.type === 'number'"
              v-model="obj.queryValue"
              controls-position="right"
              :min="0"
            ></el-input-number>
            <el-input
              v-else
              type="textarea"
              class="search-conditions-item-queryVal textareaVal"
              :autosize="{ maxRows: 3 }"
              v-model="obj.queryValue"
              placeholder="如需查询多值，请用换行进行区分，超过30条仅取前30条查询，若需查看更多请使用导出功能"
              @input="
                val => {
                  checkSpecificKey(val, index);
                }
              "
            ></el-input>

            <i
              v-if="searchCondition.length > 1"
              class="el-icon-minus"
              @click="delSearchCondition(index)"
            />
          </div>

          <!-- 添加搜索条件  -->
          <div class="add-search-condition" @click="addSearchCondition">
            <i class="el-icon-plus" />
            添加搜索条件
          </div>
          <el-button class="searchBtn" @click="handleSearch('search')">搜索</el-button>
          <el-button class="exportBtn" @click="handleExport()" v-if="activeTab !== '2'">
            导出
          </el-button>
        </div>
      </div>
    </div>

    <div class="advance-search-table" :class="{ 'height-sty': dataCount === 0 }">
      <div class="search-result-title" v-if="activeTab !== '2'">搜索到 {{ dataCount }} 条结果</div>
      <div class="search-result-title" v-if="activeTab === '2'">
        <div v-if="isShowSpu && !isShowSku">搜索到 {{ spuTotal }} 条结果</div>
        <div v-else-if="isShowSku && !isShowSpu">搜索到 {{ skuTotal }} 条结果</div>
        <div v-else>搜索到 {{ spuData.length + skuData.length }} 条结果</div>
      </div>
      <div class="search-table" v-if="activeTab !== '2'">
        <eyao-table
          v-show="!reqLoading"
          ref="table"
          :table-data="tableData"
          :total-num="dataCount"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
          <el-table-column
            v-for="column in configList"
            :key="column.property"
            :prop="column.property"
            :column-key="column.property"
            :width="column.width"
            :min-width="column['min-width']"
            :label="column.title"
            :sortable="column.sort"
            :fixed="column.fixed"
          >
            <template slot-scope="scope">
              <!-- id字段标蓝 点击后跳转 -->
              <template v-if="column.property === 'id'">
                <div
                  @click="gotoDetail(scope.row.id, scope.row.name, scope.row.hco_type_name2)"
                  :style="{ color: '#0069FA', cursor: 'pointer' }"
                >
                  {{ scope.row[column.property] }}
                </div>
              </template>

              <!-- name字段hover展示hco_code与hcp_code -->
              <template v-else-if="column.property === 'name'">
                <el-popover
                  placement="top-start"
                  width="160"
                  trigger="hover"
                  :content="activeTab === '1' ? scope.row.hcp_code : scope.row.hco_code"
                >
                  <span
                    slot="reference"
                    :style="{ color: '#0069FA', cursor: 'pointer' }"
                    @click="gotoDetail(scope.row.id, scope.row.name, scope.row.hco_type_name2)"
                  >
                    {{ scope.row[column.property] }}
                  </span>
                </el-popover>
              </template>

              <!-- 所属机构名称跳转详情 -->
              <template v-else-if="column.property === 'hco_name'">
                <div
                  @click="gotoHcoName(scope.row.hco_name, scope.row.hco_id)"
                  :style="{ color: '#0069FA', cursor: 'pointer' }"
                >
                  {{ scope.row[column.property] }}
                </div>
              </template>

              <!-- 涉及时间字段，format值 -->
              <template v-else-if="column.format">
                <template v-if="column.property === 'establishment_date'">
                  {{ scope.row[column.property] | getYMD }}
                </template>
                <template v-else>{{ scope.row[column.property] | getFormatTime }}</template>
              </template>

              <template v-else>{{ scope.row[column.property] }}</template>
            </template>
          </el-table-column>

          <!-- 操作列 -->
          <el-table-column width="100" fixed="right" label="操作">
            <div
              slot-scope="scope"
              v-if="getAuthorization('dataList-edit') || getAuthorization('dataList-delete')"
              class="data-list-option"
            >
              <div
                v-if="getAuthorization('dataList-edit')"
                @click="gotoEdit(scope.row.id, scope.row.name, scope.row.hco_type_name2)"
              >
                编辑
              </div>
              <div v-if="getAuthorization('dataList-delete')" @click="deleteData(scope.row.id)">
                删除
              </div>
            </div>
          </el-table-column>
          <template v-slot:empty>
            <div class="search-no-data" v-if="dataCount === 0 && !reqLoading">
              <img
                src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
                alt="未查询到相关数据"
              />
              未查询到相关数据
            </div>
          </template>
        </eyao-table>
      </div>

      <div class="search-tabs" v-if="activeTab === '2'">
        <el-tabs v-model="activeName" v-show="!reqLoading">
          <el-tab-pane :label="`药品（${spuTotal}）`" name="spu" v-if="isShowSpu">
            <eyao-table
              ref="table"
              :table-data="spuData"
              :total-num="spuTotal"
              :select-page-size="10"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
              <!-- <el-table-column type="selection" :reserve-selection="true" /> -->
              <el-table-column
                v-for="column in spuTabelConfig"
                :key="`${$route.query.type}-${column.property}`"
                :prop="column.property"
                :column-key="column.property"
                :min-width="column.width"
                :label="column.title"
                :sortable="column.sort"
                :fixed="column.fixed"
              >
                <template slot-scope="scope">
                  <!-- id字段标蓝 点击后跳转 -->
                  <template
                    v-if="column.property === 'product_name' || column.property === 'sku_name'"
                  >
                    <div
                      @click="gotoDetail(scope.row.id, scope.row.product_name, 'spu')"
                      :style="{ color: '#0069FA', cursor: 'pointer' }"
                    >
                      {{ scope.row[column.property] }}
                    </div>
                  </template>
                  <!-- 涉及时间字段，format值 -->
                  <template v-else-if="column.format">
                    <template>{{ scope.row[column.property] | getFormatTime }}</template>
                  </template>
                  <template v-else>{{ scope.row[column.property] }}</template>
                </template>
              </el-table-column>

              <!-- 操作列 -->
              <el-table-column min-width="130" fixed="right" label="操作">
                <div
                  slot-scope="scope"
                  class="data-list-option"
                  v-if="getAuthorization('dataList-edit') || getAuthorization('dataList-delete')"
                >
                  <div
                    @click="gotoEdit(scope.row.id, scope.row.product_name, 'spu')"
                    v-if="getAuthorization('dataList-edit')"
                  >
                    编辑
                  </div>
                  <div @click="deleteData(scope.row.id)" v-if="getAuthorization('dataList-delete')">
                    删除
                  </div>
                </div>
              </el-table-column>

              <template v-slot:empty>
                <div class="no_data" v-if="!reqLoading && !spuData.length">
                  <img
                    src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
                    alt="未查询到相关数据"
                  />
                  未查询到相关数据
                </div>
              </template>
            </eyao-table>
          </el-tab-pane>
          <el-tab-pane :label="`药品（SKU）（${skuTotal}）`" name="sku" v-if="isShowSku">
            <eyao-table
              ref="table"
              :table-data="skuData"
              :total-num="skuTotal"
              :select-page-size="10"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
              <!-- <el-table-column type="selection" :reserve-selection="true" /> -->
              <el-table-column
                v-for="column in skuTabelConfig"
                :key="`${$route.query.type}-${column.property}`"
                :prop="column.property"
                :column-key="column.property"
                :min-width="column.width"
                :label="column.title"
                :sortable="column.sort"
                :fixed="column.fixed"
              >
                <template slot-scope="scope">
                  <!-- id字段标蓝 点击后跳转 -->
                  <template
                    v-if="column.property === 'product_name' || column.property === 'sku_name'"
                  >
                    <div
                      @click="gotoDetail(scope.row.id, scope.row.sku_name, 'sku')"
                      :style="{ color: '#0069FA', cursor: 'pointer' }"
                    >
                      {{ scope.row[column.property] }}
                    </div>
                  </template>
                  <!-- 涉及时间字段，format值 -->
                  <template v-else-if="column.format">
                    <template>{{ scope.row[column.property] | getFormatTime }}</template>
                  </template>
                  <template v-else>{{ scope.row[column.property] }}</template>
                </template>
              </el-table-column>

              <!-- 操作列 -->
              <el-table-column min-width="130" fixed="right" label="操作">
                <div
                  slot-scope="scope"
                  class="data-list-option"
                  v-if="getAuthorization('dataList-edit') || getAuthorization('dataList-delete')"
                >
                  <div
                    @click="gotoEdit(scope.row.id, scope.row.sku_name, 'sku')"
                    v-if="getAuthorization('dataList-edit')"
                  >
                    编辑
                  </div>
                  <div @click="deleteData(scope.row.id)" v-if="getAuthorization('dataList-delete')">
                    删除
                  </div>
                </div>
              </el-table-column>

              <template v-slot:empty>
                <div class="no_data" v-if="!reqLoading && !skuData.length">
                  <img
                    src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
                    alt="未查询到相关数据"
                  />
                  未查询到相关数据
                </div>
              </template>
            </eyao-table>
          </el-tab-pane>
        </el-tabs>
      </div>

      <div class="listLoading" v-if="reqLoading">
        <img src="@/assets/loading.gif" alt="loading" />
      </div>
    </div>

    <!-- 删除窗口 -->
    <el-dialog title="确认删除吗" :visible.sync="showDeleteDialog">
      请填写删除原因
      <el-input v-model="deleteReason"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDeleteDialog = false">取 消</el-button>
        <el-button type="primary" :disabled="deleteReason.length === 0" @click="deleteConfirm">
          确 定
        </el-button>
      </div>
    </el-dialog>

    <!-- 数据导出model -->
    <el-dialog
      title="数据导出"
      :visible.sync="showDataExportDialog"
      width="680px"
      top="20vh"
      class="dataExport-warp"
      :before-close="handleCloseDialog"
    >
      <el-form :model="dataExportForm">
        <el-form-item
          label="导出数据类型"
          label-width="96px"
          :style="{ marginleft: '18px', color: '#667082' }"
        >
          {{ title }}
        </el-form-item>

        <el-form-item
          label="导出数据数量"
          label-width="96px"
          :style="{ marginleft: '18px', color: '#667082' }"
        >
          <el-select v-model="dataExportForm.quantity" clearable filterable style="width: 90px">
            <el-option
              v-for="item in dataExportForm.options"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
          <span class="num">条</span>
        </el-form-item>

        <el-form-item
          label="选择导出字段"
          label-width="96px"
          :style="{ marginleft: '18px', color: '#667082' }"
        >
          <div class="transfer-field-warp">
            <el-transfer
              ref="exportFieldTransfer"
              v-model="dataExportForm.exportRightFields"
              :data="dataExportForm.allExportFields"
              :titles="['可选字段', '导出字段']"
              target-order="push"
              @change="changeExportField"
              @right-check-change="exportCheckedFields"
            ></el-transfer>

            <div class="transfer-field-move">
              <el-button
                :disabled="dataExportForm.checkedFields.length === 0"
                @click="handleExportFieldUp"
              >
                <i class="el-icon-top"></i>
              </el-button>
              <el-button
                :disabled="dataExportForm.checkedFields.length === 0"
                @click="handleExportFieldDown"
              >
                <i class="el-icon-bottom"></i>
              </el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="dialog-footer-cancelBtn" @click="handleCloseDialog">取 消</el-button>
        <el-button
          type="primary"
          :disabled="dataExportForm.exportRightFields.length === 0 && dataExportForm.quantity !== 0"
          @click="handleSearch('export')"
        >
          导 出
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import drugSpuLabelConfig from '../drug-list/labelConfig/spu';
import drugSkuLabelConfig from '../drug-list/labelConfig/sku';
import drugSpuConfig from '../drug-list/config/drug-spu';
import drugSkuConfig from './drug-sku';
import hospitalConfig from '../data-list/config/hospital';
import dealerConfig from '../data-list/config/dealer';
import shopConfig from '../data-list/config/shop';
import doctorConfig from './doctor';
import hospitalLabelConfig from '../data-list/labelConfig/hospital';
import doctorLabelConfig from '../data-list/labelConfig/doctor';
import eyaoTable from '@/components/table';
import * as apis from '@/api/data';
import { getAdminDistrict } from '@/api/cascader-api';
import { dateFormat, getAuthorization, getDate } from '@/utils/util';
import { SPECIAL_CHAR, REQUEST_TITLE_TYPE } from '@/utils/constant';
import { hcoCustom } from '../data-list/customField';

const orgDataTypes = [
  '医院',
  '基层医疗卫生机构',
  '专业公共卫生机构',
  '其他医疗卫生机构',
  '经销商',
  '药店',
  '医疗器械',
  '生产企业',
  '其他分类',
];
const propertyNames = [
  'type_name',
  'hospital_level',
  'ownership',
  'is_medical_insurance',
  'business_status',
  'in_doubt',
  'source',
  'gender',
  'hcp_type',
  'hcp_status',
  'standard_dept_name',
  'professional_title',
  'org_type',
  'administrative_title',
  'academic_title',
  'therapeutic_area_name',
  'productline_name',
  'category',
  'is_RX',
  'source',
  'in_doubt',
  'administration',
  'prepared_form',
  'unit',
  'sales_status',
  'is_medical_insurance',
];

export default {
  components: {
    eyaoTable,
  },
  filters: {
    getFormatTime(value) {
      if (value && !value.startsWith('0')) {
        return dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
      }
      return '';
    },
    getYMD(value) {
      if (value && !value.startsWith('0')) {
        const date = new Date(value);
        let year = date.getFullYear();
        year = year < 10 ? `0${year}` : year;
        let month = date.getMonth() + 1;
        month = month < 10 ? `0${month}` : month;
        let day = date.getDate();
        day = day < 10 ? `0${day}` : day;
        return `${year}年${month}月${day}日`;
      }
      return '';
    },
  },
  data() {
    const self = this;
    return {
      isShowSpu: true,
      isShowSku: true,
      drugCheckType: [],
      activeName: 'spu',

      showDataExportDialog: false,
      dataExportForm: {
        quantity: 20,
        options: [20, 50, 100, 200],
        allExportFields: [],
        exportRightFields: [],
        checkedFields: [],
      },
      title: '',

      // tab激活的下标
      activeTab: 0,
      // 数据类型-全选按钮
      checkOrgAll: false,
      checkDocAll: false,
      checkDrugAll: false,
      // 表示 checkbox 的不确定状态
      isOrgIndeterminate: false,
      isDocIndeterminate: false,
      isDrugIndeterminate: false,
      // checkbox值
      orgDataType: orgDataTypes,
      docDataType: ['医生'],
      drugDataType: ['药品', '药品(SKU)'],
      // 选中的数据类型
      checkedDataType: [],
      // 搜索条件, 字段名称property, 运算符operator, 查询值queryValue
      searchCondition: [
        { property: 'name', operator: 1, queryValue: '', type: 'input', title: '' },
      ],
      // 配置文件
      drugSpuConfigList: [],
      drugSkuConfigList: [],
      spuTabelConfig: [],
      skuTabelConfig: [],
      configList: [],
      configObj: {},
      labelConfig: [],
      // 配置文件的标题
      titleOpts: [],
      queryValOpts: {},
      cascaderData: {
        admin_division: {
          multiple: true,
          lazy: true,
          checkStrictly: true,
          lazyLoad(node, resolve) {
            const { level, value } = node;
            const params = {
              level: `${level}`,
            };
            if (value) {
              params.code = value;
            }
            getAdminDistrict(params).then(res => {
              const nodes = res.district_list.map(item => ({
                value: item.code,
                label: item.name,
                leaf: !item.level,
              }));
              resolve(nodes);
            });
          },
        },
        standard_dept_name: {
          multiple: true,
          lazy: true,
          checkStrictly: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            const nodes = [];
            const params = {
              code: level === 0 ? '' : node.value.split(',')[0],
              level: level.toString(),
            };
            if (level > 2) {
              return;
            }
            apis.GetStandardDeptList(params).then(res => {
              res.deps.forEach(item => {
                const obj = {
                  value: item.code,
                  label: item.name,
                  leaf: !item.level,
                };
                nodes.push(obj);
              });
              resolve(nodes);
            });
          },
        },
        type_name: {
          multiple: true,
          lazy: true,
          checkStrictly: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            const nodes = [];
            apis
              .GetOrgTypeList({ level: 2 })
              .then(res => {
                const arr = res.datas.filter(ele => self.checkedDataType.includes(ele.type_name));
                console.log(arr);
                const typeId = arr.map(item => item.type_id);
                console.log(typeId);
                return typeId;
              })
              .then(typeId => {
                const params = {
                  parend_ids: level === 0 ? [...typeId] : [node.value],
                  level: level + 3,
                };
                if (level > 2) {
                  return;
                }
                apis.GetOrgTypeList(params).then(res => {
                  res.datas.forEach(item => {
                    const obj = {
                      value: item.type_id,
                      label: item.type_name,
                      leaf: !item.is_leaf,
                    };
                    nodes.push(obj);
                  });
                  resolve(nodes);
                });
              });
          },
        },
      },
      operator0: [
        { value: 1, label: '包含' },
        { value: 15, label: '不包含' },
        { value: 2, label: '等于' },
        { value: 13, label: '不等于' },
      ],
      // 文本
      operator1: [
        { value: 1, label: '包含' },
        { value: 15, label: '不包含' },
      ],
      // 下拉框(有标准值、布尔值)
      operator2: [
        { value: 2, label: '等于' },
        { value: 13, label: '不等于' },
      ],
      // 数值、日期、时间
      operator3: [
        { value: 10, label: '大于等于' },
        { value: 12, label: '小于等于' },
      ],
      operator4: [
        { value: 9, label: '大于等于' },
        { value: 11, label: '小于等于' },
      ],
      // 表格数据
      tableData: [],
      spuData: [],
      skuData: [],
      dataCount: 0,
      spuTotal: 0,
      skuTotal: 0,
      currentDataPage: 1,
      pageDataSize: 20,
      propertyName: propertyNames,
      // 删除框的显示 目标id 删除原因
      showDeleteDialog: false,
      deleteDialogTarget: '',
      deleteReason: '',
      // 数据请求loading
      reqLoading: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.switchTab();
    },
    handleTabsClick(activeName, oldActiveName) {
      console.log(activeName, oldActiveName);
      this.activeName = activeName;
      this.pageDataSize = 10;
      this.currentDataPage = 1;
      this.handleSearch('search');
    },

    // 操作权限
    getAuthorization,

    switchTab() {
      if (this.activeTab === '0') {
        this.title = '机构';
        this.checkOrgAll = true;
        this.checkedDataType = this.orgDataType;
        this.isOrgIndeterminate = false;
        // this.configList = hospitalConfig;
        this.labelConfig = hospitalLabelConfig;

        const hospital = ['医院', '基层医疗卫生机构', '专业公共卫生机构', '其他医疗卫生机构'];
        const dealer = ['经销商', '医疗器械', '生产企业', '其他分类'];
        const shop = ['药店'];
        const hospitalFlag = this.checkedDataType.find(ele => hospital.includes(ele));
        const dealerFlag = this.checkedDataType.find(ele => dealer.includes(ele));
        const shopFlag = this.checkedDataType.find(ele => shop.includes(ele));
        const configArr = [];
        if (hospitalFlag) configArr.push(...hospitalConfig);
        if (dealerFlag) configArr.push(...dealerConfig);
        if (shopFlag) configArr.push(...shopConfig);

        const arr = this.dealCommonData(configArr);
        const arr1 = hcoCustom.map(ele => ele.key);
        arr.sort((x, y) => {
          return arr1.indexOf(x.property) - arr1.indexOf(y.property);
        });
        this.configList = arr;
        console.log(this.dataExportForm.allExportFields);
      } else if (this.activeTab === '1') {
        this.title = '医疗专业人士';
        this.checkDocAll = true;
        this.checkedDataType = this.docDataType;
        this.isDocIndeterminate = false;
        this.configList = doctorConfig;
        this.labelConfig = doctorLabelConfig;
      } else {
        this.isShowSpu = true;
        this.isShowSku = true;
        this.title = '产品';
        this.checkDrugAll = true;
        this.checkedDataType = this.drugDataType;
        this.isDrugIndeterminate = false;
        this.drugSpuConfigList = drugSpuConfig;
        this.drugSkuConfigList = drugSkuConfig;
        this.spuTabelConfig = drugSpuConfig.filter(item => item.default);
        this.skuTabelConfig = drugSkuConfig.filter(item => item.default);
        const label = ['创建人', '创建时间', '更新人', '更新时间'];
        const dep = this.drugSkuConfigList.filter(item => !label.includes(item.title));
        this.configList = this.drugSpuConfigList.concat(dep);
        this.labelConfig = [...drugSpuLabelConfig, ...drugSkuLabelConfig];
      }

      this.dataExportForm.allExportFields = this.configList.map(item => {
        return { label: item.title, key: item.property };
      });

      if (this.activeTab === '0' || this.activeTab === '1') {
        this.searchCondition = [
          { property: 'name', operator: 1, queryValue: '', type: 'input', title: '' },
        ];
      }
      if (this.activeTab === '2') {
        this.searchCondition = [
          {
            property: 'product_name',
            operator: 1,
            queryValue: '',
            type: 'input',
            title: '',
          },
        ];
      }
      this.initConfig();
      this.getOptions();
      this.tableData = [];
      this.dataCount = 0;
      this.skuData = [];
      this.skuTotal = 0;
      this.spuData = [];
      this.spuTotal = 0;

      // console.log('configList', this.configList);
      // console.log('labelConfig', this.labelConfig);
    },

    initConfig() {
      const arr = [];
      this.configList.forEach(item => {
        this.configObj[item.property] = item;
        arr.push({
          value: item.property,
          label: item.title,
          search: item.search,
        });
      });
      console.log(arr);
      const newArr = [];
      if (this.activeTab === '0') {
        const hiddenField = [
          '系统ID',
          '二级分类',
          // '医院等级',
          '地址',
          '统一社会信用代码',
          '质量负责人',
          '经营方式',
          '所属行业',
          '公司类型',
        ];
        const moreField = [
          { label: '医院营利性质', search: 'select', value: 'hospital_profit_type' },
          { label: '上级机构名称', search: 'input', value: 'parent_hco' },
        ];
        arr.forEach(item => {
          if (!hiddenField.includes(item.label)) {
            newArr.push(item);
          }
        });
        newArr.splice(5, 0, ...moreField);
        const hco = { label: '机构编码', search: 'input', value: 'hco_code' };
        newArr.splice(1, 0, hco);
      } else if (this.activeTab === '1') {
        const hiddenField = [
          '系统ID',
          '姓名拼音',
          '医师级别',
          '执业类别',
          '执业地点',
          '执业范围',
          '质量负责人',
        ];
        arr.forEach(item => {
          if (!hiddenField.includes(item.label)) {
            newArr.push(item);
          }
        });
        const hcp = { label: 'HCP编码', search: 'input', value: 'hcp_code' };
        newArr.splice(1, 0, hcp);
      } else {
        const hiddenField = [
          '所属产品领域编码',
          '产品线/产品类型编码',
          '版本',
          '数据来源',
          '备注评论',
          '是否存疑',
          '存疑原因',
          '质量负责人',
          '所属产品编码',
        ];
        arr.forEach(item => {
          if (!hiddenField.includes(item.label)) {
            newArr.push(item);
          }
        });
      }
      this.titleOpts = newArr;
      console.log(this.titleOpts);
    },

    // 防止出现相同数据
    dealCommonData(arr = []) {
      if (arr.length === 0) return;
      const obj = {};
      return arr.reduce((item, next) => {
        obj[next.property] ? '' : (obj[next.property] = true && item.push(next));
        return item;
      }, []);
    },

    // 字段名称选择值
    handleOneCondition(val, index) {
      console.log(val, index);
      if (val) {
        this.searchCondition.forEach((ele, idx) => {
          this.searchCondition[index].type = this.configObj[
            this.searchCondition[index].property
          ]?.search;
          this.searchCondition[index].title = this.configObj[
            this.searchCondition[index].property
          ]?.title;
          const { property } = this.searchCondition[idx];
          if (property === 'hospital_profit_type') {
            this.searchCondition[index].type = 'select';
            this.searchCondition[index].title = '医院营利性质';
          }
          if (property === 'parent_hco') {
            this.searchCondition[index].type = 'input';
            this.searchCondition[index].title = '上级机构名称';
          }
          if (property === 'hco_code') {
            this.searchCondition[index].type = 'input';
            this.searchCondition[index].title = '机构编码';
          }
          if (property === 'hcp_code') {
            this.searchCondition[index].type = 'input';
            this.searchCondition[index].title = 'HCP编码';
          }
          // 每次更新字段名称时，默认操作符
          if (index === idx && ele.type === 'input') {
            this.searchCondition[idx].operator = 1; // 文本默认包含
          } else if (index === idx && ele.type === 'date') {
            this.searchCondition[idx].operator = 10; // 时间默认大于等于
          } else if (index === idx && ele.type === 'number') {
            this.searchCondition[idx].operator = 9; // 默认大于等于
          } else if (index === idx && (ele.type === 'select' || ele.type === 'cascader')) {
            this.searchCondition[idx].operator = 2; // 标准值默认等于
          }

          // 重新更新字段名称时，删掉之前的查询值
          if (index === idx) {
            this.searchCondition[idx].queryValue = '';
          }
        });
      } else {
        this.searchCondition[index].operator = '';
        this.searchCondition[index].queryValue = '';
      }

      console.log(this.searchCondition);
    },

    // 操作符选择值
    handleTwoCondition(val, index) {
      this.searchCondition[index].operator = val;
      console.log(this.searchCondition);
    },

    // 查询值选择值
    handleThreeCondition(val, index) {
      console.log(val, index);
      this.searchCondition[index].queryValue = val;
      console.log(this.searchCondition);
    },

    // 查询值为级联
    hanldeCascaderVal(val, index) {
      console.log(val, index);
      // const labelList = [];
      // const node = this.$refs[`cascaderMallCatergory-${index}`][0].getCheckedNodes();
      // node.forEach(ele => {
      //   labelList.push(ele.pathLabels);
      // });
      // console.log(labelList);

      // this.searchCondition[index].queryValue = val.join(',');
      console.log(this.searchCondition);
    },

    // 查询值为日期
    hanldePickerVal(val, index) {
      console.log(val, index);
      const { year, month, date } = getDate(new Date(val));
      this.searchCondition[index].queryValue = `${year}-${month}-${date}`;
    },

    // 查询值为input，禁止输入特殊符号
    checkSpecificKey(val, index) {
      console.log(val, index);
      if (SPECIAL_CHAR.test(val)) {
        this.$message.error('不能输入特殊字符');
        this.searchCondition[index].queryValue = '';
      }
      // 输入空格隔开，表示多值
      // const zero = /[\u0020]/;
      // if (zero.test(val)) {
      //   console.log(1);
      //   const spaceVal = val
      //     .trim()
      //     .split(' ')
      //     .join(',');
      //   console.log(spaceVal);
      // }
      console.log(this.searchCondition);
    },

    // 添加搜索条件
    addSearchCondition() {
      if (this.searchCondition.length < 10) {
        this.searchCondition.push({
          property: '',
          operator: '',
          queryValue: '',
          type: 'input',
          title: '',
        });
      } else {
        this.$message.error('最多只能添加10个查询条件');
      }
      console.log(this.searchCondition);
    },

    // 删除搜索条件
    delSearchCondition(idx) {
      this.searchCondition.splice(idx, 1);
    },

    // 全选按钮
    handleCheckAllChange(val) {
      console.log(val);
      if (this.activeTab === '0') {
        this.checkedDataType = val ? orgDataTypes : [];
        this.isOrgIndeterminate = false;
      } else if (this.activeTab === '1') {
        this.checkedDataType = val ? this.docDataType : [];
        this.isDocIndeterminate = false;
      } else {
        this.checkedDataType = val ? this.drugDataType : [];
        if (val) {
          this.drugCheckType = this.drugDataType;
        } else {
          this.drugCheckType = [];
        }
        this.isDrugIndeterminate = false;
      }
    },

    // 勾选数据类型
    handleOrgChecked(value) {
      console.log(value);
      const checkedCount = value.length;
      this.checkOrgAll = checkedCount === this.orgDataType.length;
      this.isOrgIndeterminate = checkedCount > 0 && checkedCount < this.orgDataType.length;
      this.searchCondition = [
        { property: 'name', operator: 1, queryValue: '', type: 'input', title: '' },
      ];
    },
    handleDocChecked(value) {
      console.log(value);
      const checkedCount = value.length;
      this.checkDocAll = checkedCount === this.docDataType.length;
      this.isDocIndeterminate = checkedCount > 0 && checkedCount < this.docDataType.length;
      this.searchCondition = [
        { property: 'name', operator: 1, queryValue: '', type: 'input', title: '' },
      ];
    },
    handleDrugChecked(value) {
      console.log(value);
      this.drugCheckType = value;
      const checkedCount = value.length;
      this.checkDrugAll = checkedCount === this.drugDataType.length;
      this.isDrugIndeterminate = checkedCount > 0 && checkedCount < this.drugDataType.length;
      this.searchCondition = [
        { property: 'product_name', operator: 1, queryValue: '', type: 'input', title: '' },
      ];
    },

    getOptions() {
      const optionsApi = [];
      const opts = {};
      if (this.activeTab !== '2') {
        this.titleOpts.forEach(item => {
          if (item.search === 'select') {
            let params = {
              dict_api_name: item.value,
              dict_type: this.activeTab,
            };
            if (item.value === 'source') {
              params = {
                dict_api_name: 'source',
                dict_type: '4',
              };
            }

            if (item.value === 'type_name') return;
            optionsApi.push(
              apis.GetDictOptionList(params).then(res => {
                opts[item.value] = res.dict_list.map(opt => {
                  return {
                    text: opt.label,
                    label: opt.label,
                    value: opt.code,
                  };
                });
                return true;
              }),
            );
          }
        });
        Promise.all(optionsApi).then(() => {
          Object.keys(opts).forEach(key => {
            this.$set(this.queryValOpts, key, opts[key]);
          });
        });
        console.log(this.queryValOpts, '下拉框opt');
      } else {
        // 获取所属产品领域名称
        apis
          .GetDictOptionList({ dict_api_name: 'therapeutic_area_code', dict_type: '5' })
          .then(res => {
            this.queryValOpts.therapeutic_area_name = res.dict_list.map(opt => {
              return {
                text: opt.label,
                label: opt.label,
                value: opt.code,
              };
            });
          })
          .catch(err => {
            this.$message.error(err.message);
          });
        // 获取产品线/产品类型名称
        apis
          .GetDictOptionList({ dict_api_name: 'productline_code', dict_type: '5' })
          .then(res => {
            this.queryValOpts.productline_name = res.dict_list.map(opt => {
              return {
                text: opt.label,
                label: opt.label,
                value: opt.code,
              };
            });
          })
          .catch(err => {
            this.$message.error(err.message);
          });
        // 获取药品类型
        apis
          .GetDictOptionList({ dict_api_name: 'category', dict_type: '5' })
          .then(res => {
            this.queryValOpts.category = res.dict_list.map(opt => {
              return {
                text: opt.label,
                label: opt.label,
                value: opt.code,
              };
            });
          })
          .catch(err => {
            this.$message.error(err.message);
          });
        // 获取是否处方药
        apis
          .GetDictOptionList({ dict_api_name: 'is_RX', dict_type: '5' })
          .then(res => {
            this.queryValOpts.is_RX = res.dict_list.map(opt => {
              return {
                text: opt.label,
                label: opt.label,
                value: opt.code,
              };
            });
          })
          .catch(err => {
            this.$message.error(err.message);
          });
        // 获取剂型分类
        apis
          .GetDictOptionList({ dict_api_name: 'administration', dict_type: '6' })
          .then(res => {
            this.queryValOpts.administration = res.dict_list.map(opt => {
              return {
                text: opt.label,
                label: opt.label,
                value: opt.code,
              };
            });
          })
          .catch(err => {
            this.$message.error(err.message);
          });

        // 获取剂型
        apis
          .GetDictOptionList({ dict_api_name: 'prepared_form', dict_type: '6' })
          .then(res => {
            this.queryValOpts.prepared_form = res.dict_list.map(opt => {
              return {
                text: opt.label,
                label: opt.label,
                value: opt.code,
              };
            });
          })
          .catch(err => {
            this.$message.error(err.message);
          });
        // 获取单位
        apis
          .GetDictOptionList({ dict_api_name: 'unit', dict_type: '6' })
          .then(res => {
            this.queryValOpts.unit = res.dict_list.map(opt => {
              return {
                text: opt.label,
                label: opt.label,
                value: opt.code,
              };
            });
          })
          .catch(err => {
            this.$message.error(err.message);
          });
        // 获取销售状态
        apis
          .GetDictOptionList({ dict_api_name: 'sales_status', dict_type: '6' })
          .then(res => {
            this.queryValOpts.sales_status = res.dict_list.map(opt => {
              return {
                text: opt.label,
                label: opt.label,
                value: opt.code,
              };
            });
          })
          .catch(err => {
            this.$message.error(err.message);
          });
        // 获取是否医保
        apis
          .GetDictOptionList({ dict_api_name: 'is_medical_insurance', dict_type: '6' })
          .then(res => {
            this.queryValOpts.is_medical_insurance = res.dict_list.map(opt => {
              return {
                text: opt.label,
                label: opt.label,
                value: opt.code,
              };
            });
          })
          .catch(err => {
            this.$message.error(err.message);
          });
        console.log(this.queryValOpts, '下拉框opt');
      }
    },

    // 点击搜索
    handleSearch(types) {
      console.log(this.checkedDataType);
      console.log(this.searchCondition);
      if (this.checkedDataType.length === 0) {
        this.$message.error('请选择查询数据类型');
      } else {
        this.currentDataPage = 1;
        this.pageDataSize = 20;
        if (this.drugCheckType.length === 2) {
          this.activeName = 'spu';
        }
        this.spuTotal = 0;
        this.skuTotal = 0;
        this.beforeSearch(types);
      }
    },

    beforeSearch(types) {
      // 对勾选的数据类型，只展示该列表下的字段名称
      if (this.activeTab === '0') {
        const hospital = ['医院', '基层医疗卫生机构', '专业公共卫生机构', '其他医疗卫生机构'];
        const dealer = ['经销商', '医疗器械', '生产企业', '其他分类'];
        const shop = ['药店'];
        const hospitalFlag = this.checkedDataType.find(ele => hospital.includes(ele));
        const dealerFlag = this.checkedDataType.find(ele => dealer.includes(ele));
        const shopFlag = this.checkedDataType.find(ele => shop.includes(ele));
        const configArr = [];
        if (hospitalFlag) configArr.push(...hospitalConfig);
        if (dealerFlag) configArr.push(...dealerConfig);
        if (shopFlag) configArr.push(...shopConfig);

        const arr = this.dealCommonData(configArr);
        const arr1 = hcoCustom.map(ele => ele.key);
        arr.sort((x, y) => {
          return arr1.indexOf(x.property) - arr1.indexOf(y.property);
        });
        this.configList = arr;

        this.dataExportForm.allExportFields = this.configList.map(item => {
          return { label: item.title, key: item.property };
        });
        console.log(this.dataExportForm.allExportFields);
      }

      // 判断搜索条件是否为空
      // const arr = [];
      // this.searchCondition.forEach(ele => {
      //   if (ele.property && ele.operator && ele.queryValue) {
      //     arr.push(ele);
      //   } else if (!ele.property) {
      //     this.$message.error('请选择查询数据类型');
      //   } else if (!ele.queryValue || !ele.operator) {
      //     this.$message.error('请填写搜索字段查询值');
      //   }
      // });
      // console.log(arr, '搜索条件');
      // 都符合搜索条件要求
      // if (arr.length === this.searchCondition.length) {
      //   this.dealConditionData(arr);
      // }

      // 支持满足一个有效条件就搜索
      console.log(this.searchCondition);
      this.searchCondition.forEach(item => {
        if (item.property === 'cost_price' || item.property === 'sales_price') {
          item.queryValue = String(item.queryValue);
        }
      });
      const arr = this.searchCondition.filter(ele =>
        ele.property && ele.operator && Array.isArray(ele.queryValue)
          ? ele.queryValue.length > 0
          : ele.queryValue,
      );
      console.log(arr);
      if (arr.length > 0) {
        this.dealConditionData(arr, types);
      } else {
        this.$message.error('请填写搜索字段查询值');
      }
    },

    // 处理搜索前的所有条件值
    dealConditionData(arr, types) {
      const condParams = [];
      const speciaType = ['is_medical_insurance', 'in_doubt', 'gender'];
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        if (item.type === 'select') {
          const queryValue = item.queryValue.join(',');
          let ope = '';
          // 数值的等于
          if (speciaType.includes(item.property) && item.operator === 2) {
            ope = 3;
          }
          // 数值的不等于
          if (speciaType.includes(item.property) && item.operator === 13) {
            ope = 14;
          }
          condParams.push({
            ...item,
            queryValue,
            operator: ope ? ope : item.operator,
          });
        } else if (item.type === 'cascader') {
          const arr = [];
          item.queryValue.forEach(e => {
            arr.push(e.join(','));
          });
          if (item.property === 'type_name') {
            const arr1 = [];
            item.queryValue.forEach(e => {
              arr1.push(`,,${e.join(',')}`);
            });
            condParams.push({
              operator: item.operator,
              property: 'type_detail',
              queryValue: arr1,
              type: item.type,
            });
          } else if (item.property === 'admin_division') {
            condParams.push({
              operator: item.operator,
              property: 'admin_division',
              queryValue: arr,
              type: item.type,
            });
          } else if (item.property === 'standard_dept_name') {
            condParams.push({
              operator: item.operator,
              property: 'department_detail',
              queryValue: arr,
              type: item.type,
            });
          }
        } else if (item.type === 'input') {
          // 有空格，表示多值查询
          let queryValue = item.queryValue.trim().replace(/\n/g, ',');
          console.log(queryValue, queryValue.split(',').length);
          if (types === 'search') {
            if (queryValue.split(',').length > 30) {
              queryValue = queryValue
                .split(',')
                .slice(0, 30)
                .toString();
            }
          }
          condParams.push({ ...item, queryValue });
        } else {
          condParams.push(item);
        }
      }
      console.log(condParams);
      // this.searchRequest(condParams);

      if (types === 'search') {
        this.getSearchMainData(condParams);
      }
      if (types === 'export') {
        this.comfirmExportData(condParams);
      }
    },

    // 如果property相同，那queryValue做拼接处理
    formatRepeatVal(proper) {
      const formatArr = [];
      const tempArr = [];
      for (let i = 0; i < proper.length; i++) {
        if (tempArr.indexOf(proper[i].property) === -1) {
          formatArr.push({
            operator: proper[i].operator,
            property: proper[i].property,
            queryValue: proper[i].queryValue,
          });
          tempArr.push(proper[i].property);
        } else {
          for (let j = 0; j < formatArr.length; j++) {
            if (formatArr[j].property === proper[i].property) {
              formatArr[j].queryValue = `${formatArr[j].queryValue},${proper[i].queryValue}`;
            }
          }
        }
      }
      return formatArr;
    },

    searchMainDataOption(condParams) {
      console.log(condParams);
      const dataCondition = [];
      condParams.forEach(ele => {
        dataCondition.push({
          field_name: ele.property,
          type: ele.operator,
          keywords: ele.type === 'cascader' ? ele.queryValue : ele.queryValue?.split(','),
        });
      });

      const firstCondition = {
        field_name: 'hco_type',
        type: 2,
        keywords: this.checkedDataType,
      };

      if (this.activeTab === '0') {
        return [firstCondition, ...dataCondition];
      }
      return [...dataCondition];
    },

    getSearchMainData(condParams) {
      const spu = drugSpuConfig.map(item => item.property);
      const sku = drugSkuConfig.map(item => item.property);
      this.reqLoading = true;
      let condis = [];
      condis = this.searchMainDataOption(condParams);

      if (this.activeTab === '2') {
        if (this.drugCheckType.length === 1) {
          if (this.drugCheckType.includes('药品')) {
            const spuParams = [];
            const skuParams = [];
            console.log(condis);
            condis.forEach(item => {
              if (spu.includes(item.field_name)) {
                spuParams.push(item);
              }
              if (item.field_name === 'product_name') {
                skuParams.push(item);
              }
            });
            if (!spuParams.length) {
              this.$message.error('搜索条件字段不在该数据类型中，请检查后重试');
              this.reqLoading = false;
              return false;
            }

            if (skuParams.length) {
              const params1 = {
                main_data: 't_product',
                index: this.currentDataPage,
                page_size: this.pageDataSize,
                fields: this.dealDivisionParam(),
                conditions: spuParams,
              };
              const params2 = {
                main_data: 't_product_sku',
                index: this.currentDataPage,
                page_size: this.pageDataSize,
                fields: this.dealDivisionParam(),
                conditions: skuParams,
              };

              Promise.all([apis.SearchMainData(params1), apis.SearchMainData(params2)]).then(
                res => {
                  console.log(res);
                  let depSpu = [];
                  let depSku = [];
                  if (res[0].data.length) {
                    depSpu = res[0].data.map(item => JSON.parse(item));
                  }
                  if (res[1].data.length) {
                    depSku = res[1].data.map(item => JSON.parse(item));
                  }
                  this.dealDataLabel(depSpu);
                  this.dealDataLabel(depSku);
                  this.spuData = depSpu;
                  this.skuData = depSku;
                  this.spuTotal = res[0].total_num;
                  this.skuTotal = res[1].total_num;
                  this.reqLoading = false;
                },
              );
            } else {
              const params1 = {
                main_data: 't_product',
                index: this.currentDataPage,
                page_size: this.pageDataSize,
                fields: this.dealDivisionParam(),
                conditions: spuParams,
              };
              apis.SearchMainData(params1).then(res => {
                let depSpu = [];
                if (res.data.length) {
                  depSpu = res.data.map(item => JSON.parse(item));
                }
                this.dealDataLabel(depSpu);
                this.spuData = depSpu;
                this.skuData = [];
                this.skuTotal = 0;
                this.spuTotal = res.total_num;
                this.reqLoading = false;
              });
            }
            this.activeName = 'spu';
            console.log(123);
            this.isShowSku = false;
            this.isShowSpu = true;
          } else {
            const skuParams = [];
            condis.forEach(item => {
              if (sku.includes(item.field_name) && item.field_name !== 'sku_product_name') {
                skuParams.push(item);
              }
              if (item.field_name === 'sku_product_name') {
                skuParams.push({
                  field_name: 'product_name',
                  type: item.type,
                  keywords: item.keywords,
                });
              }
            });
            if (!skuParams.length) {
              this.$message.error('搜索条件字段不在该数据类型中，请检查后重试');
              this.reqLoading = false;
              return false;
            }
            const params = {
              main_data: 't_product_sku',
              index: this.currentDataPage,
              page_size: this.pageDataSize,
              fields: this.dealDivisionParam(),
              conditions: skuParams,
            };
            apis.SearchMainData(params).then(res => {
              let depSku = [];
              if (res.data.length) {
                depSku = res.data.map(item => JSON.parse(item));
              }
              this.dealDataLabel(depSku);
              this.skuData = depSku;
              this.spuData = [];
              this.spuTotal = 0;
              this.skuTotal = res.total_num;
              this.reqLoading = false;
            });

            this.isShowSpu = false;
            this.isShowSku = true;
            this.activeName = 'sku';
          }
        } else {
          const spuParams = [];
          let skuParams = [];
          condis.forEach(item => {
            if (spu.includes(item.field_name)) {
              spuParams.push(item);
            }
            if (sku.includes(item.field_name) && item.field_name !== 'sku_product_name') {
              skuParams.push(item);
            }
            if (item.field_name === 'sku_product_name') {
              skuParams.push({
                field_name: 'product_name',
                type: item.type,
                keywords: item.keywords,
              });
            }
          });

          if (
            !skuParams.some(item => item.field_name === 'product_name') &&
            spuParams.some(item => item.field_name === 'product_name')
          ) {
            const productName = spuParams.filter(item => item.field_name === 'product_name');
            skuParams = skuParams.concat(productName);
          }

          const params1 = {
            main_data: 't_product',
            index: this.currentDataPage,
            page_size: this.pageDataSize,
            fields: this.dealDivisionParam(),
            conditions: spuParams,
          };
          const params2 = {
            main_data: 't_product_sku',
            index: this.currentDataPage,
            page_size: this.pageDataSize,
            fields: this.dealDivisionParam(),
            conditions: skuParams,
          };
          const reqArr = [];
          if (spuParams.length) {
            reqArr.push(apis.SearchMainData(params1));
          }
          if (skuParams.length) {
            reqArr.push(apis.SearchMainData(params2));
          }

          Promise.all(reqArr).then(res => {
            console.log(res);
            let depSpu = [];
            let depSku = [];
            if (res.length === 1) {
              if (res[0]?.data.length && !spuParams.length && skuParams.length) {
                depSku = res[0]?.data.map(item => JSON.parse(item));
                this.skuTotal = res[0]?.total_num || 0;
                this.spuTotal = 0;
                // this.activeName = 'sku';
              } else if (res[0]?.data.length && spuParams.length && !skuParams.length) {
                depSpu = res[0]?.data.map(item => JSON.parse(item));
                this.spuTotal = res[0]?.total_num || 0;
                this.skuTotal = 0;
                // this.activeName = 'spu';
              }
            } else {
              depSpu = res[0]?.data.map(item => JSON.parse(item)) || [];
              this.spuTotal = res[0]?.total_num || 0;
              depSku = res[1]?.data.map(item => JSON.parse(item)) || [];
              this.skuTotal = res[1]?.total_num || 0;
              // this.activeName = 'spu';
            }

            this.dealDataLabel(depSpu);
            this.dealDataLabel(depSku);
            this.spuData = depSpu;
            this.skuData = depSku;
            this.reqLoading = false;
          });
          this.isShowSpu = true;
          this.isShowSku = true;
        }
      } else {
        const params = {
          main_data: this.activeTab === '0' ? 't_hco' : 't_hcp',
          index: this.currentDataPage,
          page_size: this.pageDataSize,
          fields: this.dealDivisionParam(),
          conditions: condis,
        };
        console.log(params);
        apis
          .SearchMainData(params)
          .then(res => {
            console.log(res);
            if (res && res.data) {
              const dataList = res.data.map(item => JSON.parse(item));
              this.dataCount = res.total_num;

              if (this.activeTab === '0') {
                // 处理行政区划、二级分类、类型细分
                this.dealFieldData(dataList);
              } else {
                // 处理标准科室
                this.dealStandardData(dataList);
              }

              this.dealDataLabel(dataList);
              this.tableData = dataList;
              console.log(this.tableData, 'tableData');
              this.reqLoading = false;
            }
          })
          .catch(err => {
            this.$message.error(`${err.message}`);
            this.reqLoading = false;
          });
      }
    },

    // 搜索请求
    searchRequest(conditions) {
      this.reqLoading = true;
      const searchOptions = [];
      conditions.forEach(ele => {
        searchOptions.push({
          field_name: ele.property, // 字段名称
          data_name: this.activeTab === '0' ? 't_hco' : 't_hcp', // 表名
          type: ele.operator, // 筛选类型； 1-包含，2-字符串的等于，3-大于，4-小于，5-大于等于，6-小于等于，7-不等于，8-不包含, 9-数字的等于
          key: ele.queryValue,
        });
      });
      console.log(searchOptions, 'searchOptions');

      const params = {
        main_data: this.activeTab === '0' ? 't_hco' : 't_hcp',
        data: '',
        keyword: '',
        index: this.currentDataPage,
        page_size: this.pageDataSize,
        search_fields: this.dealDivisionParam(),
        SearchOptions: searchOptions,
        data_array: this.checkedDataType,
      };

      apis
        .SearchMainDataByType(params)
        .then(res => {
          if (res && res.data) {
            const dataList = res.data.map(item => JSON.parse(item));
            this.dataCount = res.total_num;

            if (this.activeTab === '0') {
              // 处理行政区划、二级分类、类型细分
              this.dealFieldData(dataList);
            } else {
              // 处理标准科室
              this.dealStandardData(dataList);
            }

            this.dealDataLabel(dataList);
            this.tableData = dataList;
            console.log(this.tableData, 'tableData');
            setTimeout(() => {
              this.reqLoading = false;
            }, 500);
          }
        })
        .catch(err => {
          console.log(err);
          this.$message.error(`${err.message}`);
          this.reqLoading = false;
        });
    },

    // 处理请求字段
    dealDivisionParam() {
      let allProperty = this.configList.map(item => item.property);

      // 医生，标准科室
      if (this.activeTab === '1') {
        allProperty = [...allProperty, 'standard_dept_id', 'id', 'hcp_code', 'hco_id'];
      }

      // 机构，行政区划、二级分类、类型细分
      if (this.activeTab === '0') {
        const arr = [
          'province_name',
          'city_name',
          'district_name',
          'town_name',
          'village_name',
          'province_code',
          'city_code',
          'district_code',
          'town_code',
          'village_code',
        ];
        const hcoTypeArr = [
          'hco_type1',
          'hco_type_name1',
          'hco_type2',
          'hco_type_name2',
          'hco_type3',
          'hco_type_name3',
          'hco_type4',
          'hco_type_name4',
          'hco_type5',
          'hco_type_name5',
        ];
        allProperty = [...allProperty, ...hcoTypeArr, ...arr, 'id', 'hco_code'];
      }
      if (this.activeTab === '2') {
        allProperty = [...allProperty, 'id'];
      }

      return allProperty;
    },

    dealFieldData(dataList) {
      dataList.forEach(ele => {
        const adminDivision = `${ele.province_name}/${ele.city_name}/${ele.district_name}/${ele.town_name}/${ele.village_name}`;
        ele.admin_division = this.delEndStr(adminDivision);

        // const typeName = `${ele.hco_type_name3}/${ele.hco_type_name4}/${ele.hco_type_name5}`;
        // ele.type_name = this.delEndStr(typeName);
        ele.hco_type = ele.hco_type_name2;
      });
    },

    dealStandardData(dataList) {
      dataList.forEach(ele => {
        if (ele.standard_dept_name.indexOf(',') > -1) {
          ele.standard_dept_name = ele.standard_dept_name.replace(/,/g, '/');
        }
      });
    },

    // 处理列表中下拉菜单的label值
    dealDataLabel(list) {
      const propertyArr = ['standard_dept_name', 'type_name'];
      const reg = /^[0-9a-zA-Z]/;
      list.forEach(ele => {
        Object.keys(ele).forEach(key => {
          if (propertyArr.includes(key)) return;
          if (this.propertyName.includes(key)) {
            // console.log(key, ele[key]);
            if (reg.test(ele[key])) {
              ele[key] = this.getCodeLabel(key, ele[key]);
            }
          }
        });
      });
    },

    getCodeLabel(key, value) {
      const valObj = this.labelConfig.filter(item => item.property === key);
      let valArr = [];
      if (JSON.stringify(valObj) !== '{}') {
        valArr = valObj[0].value;
      }
      let res = '';
      if (valArr.length > 0) {
        const arr = valArr.filter(item => item.code == value);
        if (arr.length > 0) {
          res = arr[0].label;
        }
      }
      return res;
    },

    // 递归删除'/'
    delEndStr(str) {
      if (str.endsWith('/')) {
        return this.delEndStr(str.substr(0, str.length - 1));
      }
      return str;
    },

    // pagination操作
    handleCurrentChange(val) {
      console.log(val);
      this.currentDataPage = val;
      this.handleSearch('search');
    },
    handleSizeChange(val) {
      console.log(val);
      this.pageDataSize = val;
      this.handleSearch('search');
    },

    // 进入详情页
    gotoDetail(id, name, hcoType) {
      console.log(id, name, hcoType);
      if (this.activeTab !== '2') {
        const source = this.getSource(hcoType);
        const routerData = this.$router.resolve({
          path: '/dashboard/data-detail',
          query: { id, type: 'preview', source, name },
        });
        window.open(decodeURIComponent(routerData.href), '_blank');
      } else {
        const routerData = this.$router.resolve({
          path: '/dashboard/drug-detail',
          query: { id, type: 'preview', source: hcoType },
        });
        window.open(decodeURIComponent(routerData.href), '_blank');
      }
    },

    // 所属机构名称跳转详情
    gotoHcoName(hcoName, hcoId) {
      const req = {
        obj_name: 't_hco',
        data_id: hcoId,
        fields: ['hco_type'],
      };
      apis
        .GetMainDataDetail(req)
        .then(res => {
          if (res?.data_detail) {
            const hcoType = JSON.parse(res.data_detail).hco_type;
            let source = '';
            Object.keys(REQUEST_TITLE_TYPE).forEach(ele => {
              if (REQUEST_TITLE_TYPE[ele] === hcoType) {
                source = ele;
              }
            });
            const routerData = this.$router.resolve({
              path: '/dashboard/data-detail',
              query: {
                id: hcoId,
                name: hcoName,
                type: 'preview',
                source,
              },
            });
            // console.log(decodeURIComponent(routerData.href));
            window.open(decodeURIComponent(routerData.href), '_blank');
          }
        })
        .catch(() => {
          this.$message.error('查不到数据，该数据可能已经被删除');
        });
    },

    // 进入编辑页
    gotoEdit(id, name, hcoType) {
      if (this.activeTab !== '2') {
        const source = this.getSource(hcoType);
        const routerData = this.$router.resolve({
          path: '/dashboard/data-detail',
          query: { id, type: 'edit', source, name },
        });
        console.log(routerData);
        window.open(decodeURIComponent(routerData.href), '_blank');
      } else {
        const routerData = this.$router.resolve({
          path: '/dashboard/drug-detail',
          query: { id, type: 'edit', source: hcoType },
        });
        window.open(decodeURIComponent(routerData.href), '_blank');
      }
    },

    getSource(hcoType) {
      if (!hcoType) return 'doctor';
      let source = '';
      const obj = {
        hospital: '医院',
        'hospital-ji': '基层医疗卫生机构',
        'hospital-pro': '专业公共卫生机构',
        'hospital-other': '其他医疗卫生机构',
        dealer: '经销商',
        shop: '药店',
        manufacturer: '生产企业',
        'medical-machine': '医疗器械',
        other: '其他分类',
        doctor: '医生',
      };
      Object.keys(obj).forEach(ele => {
        if (obj[ele] === hcoType) {
          source = ele;
        }
      });
      return source;
    },

    // 将删除数据的id记录后弹框
    deleteData(id) {
      this.deleteDialogTarget = id;
      this.showDeleteDialog = true;
    },
    // 确认删除
    deleteConfirm() {
      console.log('delete', this.deleteDialogTarget, this.deleteReason);
      if (this.activeTab !== '2') {
        const params = {
          obj_name: this.activeTab === '0' ? 't_hco' : 't_hcp',
          data_id: this.deleteDialogTarget,
          reason: this.deleteReason,
        };
        apis.DeleteMainDataDetail(params).then(res => {
          console.log(res, '-------');
          this.$message.success('删除成功');
          setTimeout(() => {
            // this.searchData();
            this.handleSearch('search');
          }, 500);
        });
        this.showDeleteDialog = false;
        this.deleteDialogTarget = '';
      } else {
        const params = {
          obj_name: this.activeName === 'spu' ? 't_product' : 't_product_sku',
          data_id: this.deleteDialogTarget,
          reason: this.deleteReason,
        };
        apis.DeleteMainDataDetail(params).then(res => {
          console.log(res, '-------');
          this.$message.success('删除成功');
          setTimeout(() => {
            // this.searchData();
            this.handleSearch('search');
          }, 500);
        });
        this.showDeleteDialog = false;
        this.deleteDialogTarget = '';
      }
    },
    handleExport() {
      // 对勾选的数据类型，只展示该列表下的字段名称
      if (this.checkedDataType.length === 0) {
        this.$message.error('请选择要导出数据类型');
        return false;
      }
      if (this.activeTab === '0') {
        const hospital = ['医院', '基层医疗卫生机构', '专业公共卫生机构', '其他医疗卫生机构'];
        const dealer = ['经销商', '医疗器械', '生产企业', '其他分类'];
        const shop = ['药店'];
        const hospitalFlag = this.checkedDataType.find(ele => hospital.includes(ele));
        const dealerFlag = this.checkedDataType.find(ele => dealer.includes(ele));
        const shopFlag = this.checkedDataType.find(ele => shop.includes(ele));
        const configArr = [];
        if (hospitalFlag) configArr.push(...hospitalConfig);
        if (dealerFlag) configArr.push(...dealerConfig);
        if (shopFlag) configArr.push(...shopConfig);

        const arr = this.dealCommonData(configArr);
        const arr1 = hcoCustom.map(ele => ele.key);
        arr.sort((x, y) => {
          return arr1.indexOf(x.property) - arr1.indexOf(y.property);
        });
        this.configList = arr;

        this.dataExportForm.allExportFields = this.configList.map(item => {
          return { label: item.title, key: item.property };
        });
      }
      console.log(this.dataExportForm.allExportFields);
      // 判断搜索条件是否为空
      // const arr = [];
      // this.searchCondition.forEach(ele => {
      //   if (ele.property && ele.operator && ele.queryValue) {
      //     arr.push(ele);
      //   } else if (!ele.property) {
      //     this.$message.error('请选择查询数据类型');
      //   } else if (!ele.queryValue || !ele.operator) {
      //     this.$message.error('请填写搜索字段查询值');
      //   }
      // });
      // console.log(arr, '搜索条件');
      // 都符合搜索条件要求
      // if (arr.length === this.searchCondition.length) {
      //   this.dealConditionData(arr);
      // }

      // 支持满足一个有效条件就搜索
      const arr = this.searchCondition.filter(ele =>
        ele.property && ele.operator && Array.isArray(ele.queryValue)
          ? ele.queryValue.length > 0
          : ele.queryValue,
      );
      // console.log(arr);
      if (arr.length > 0) {
        this.showDataExportDialog = true;
      } else {
        this.$message.error('请填写搜索字段查询值');
      }
    },
    comfirmExportFields() {
      // console.log(this.dataExportForm.exportRightFields);
      let exportFieldArr = [];
      // 行政区划
      const adminArr = [
        'province_name',
        'city_name',
        'district_name',
        'town_name',
        'village_name',
        'province_code',
        'city_code',
        'district_code',
        'town_code',
        'village_code',
      ];
      // 二级分类、类型细分
      const hcoTypeArr = [
        'hco_type1',
        'hco_type_name1',
        'hco_type2',
        'hco_type_name2',
        'hco_type3',
        'hco_type_name3',
        'hco_type4',
        'hco_type_name4',
        'hco_type5',
        'hco_type_name5',
      ];
      // 标准科室
      const standardArr = [
        'standard_dept1',
        'standard_dept2',
        'standard_dept3',
        'standard_dept_name1',
        'standard_dept_name2',
        'standard_dept_name3',
      ];
      this.dataExportForm.exportRightFields.forEach(ele => {
        if (ele === 'admin_division') {
          exportFieldArr.push(...adminArr);
        }
        if (ele === 'type_name') {
          exportFieldArr.push(...hcoTypeArr);
        }
        if (ele === 'standard_dept_name') {
          exportFieldArr.push(...standardArr);
        }
        exportFieldArr.push(ele);
      });
      const arr = ['admin_division', 'type_name', 'standard_dept_name'];
      exportFieldArr = exportFieldArr.filter(item => !arr.includes(item));
      console.log(exportFieldArr, '要导出的字段');

      return exportFieldArr;
    },
    // 选择导出数据的数量
    // chooseNum(val) {
    //   const result = this.dataExportForm.options.find(item => item.value === val).label;
    //   console.log(result);
    //   this.dataExportForm.quantity = result;
    // },
    // 关闭导出数据model
    handleCloseDialog() {
      this.dataExportForm.quantity = 20;
      this.showDataExportDialog = false;
      this.dataExportForm.exportRightFields = [];
      this.dataExportForm.checkedFields = [];
      // 重置滚动条
      this.$nextTick(() => {
        this.$refs.exportFieldTransfer.$children[0].$children[1].$el.scrollTop = 0;
        this.$refs.exportFieldTransfer.$children[0].checked = [];
      });
    },
    // 导出字段上移
    handleExportFieldUp() {
      if (this.dataExportForm.checkedFields.length === 1) {
        const idx = this.dataExportForm.exportRightFields.findIndex(
          item => item === this.dataExportForm.checkedFields[0],
        );
        // console.log(idx);
        if (idx === 0) {
          this.$message.warning('没有上移空间了');
          return;
        }
        // 取出上移字段
        const changeItem = JSON.parse(
          JSON.stringify(this.dataExportForm.exportRightFields[idx - 1]),
        );
        // 删除字段
        this.dataExportForm.exportRightFields.splice(idx - 1, 1);
        // 插入字段
        this.dataExportForm.exportRightFields.splice(idx, 0, changeItem);
      } else {
        this.$message.error('一次只能移动一个字段');
      }
    },

    // 导出字段上移
    handleExportFieldDown() {
      if (this.dataExportForm.checkedFields.length === 1) {
        const idx = this.dataExportForm.exportRightFields.findIndex(
          item => item === this.dataExportForm.checkedFields[0],
        );
        // console.log(idx);
        if (idx === this.dataExportForm.exportRightFields.length - 1) {
          this.$message.warning('没有下移空间了');
          return;
        }
        // 取出下移字段
        const changeItem = JSON.parse(JSON.stringify(this.dataExportForm.exportRightFields[idx]));
        // 删除字段
        this.dataExportForm.exportRightFields.splice(idx, 1);
        // 插入字段
        this.dataExportForm.exportRightFields.splice(idx + 1, 0, changeItem);
      } else {
        this.$message.error('一次只能移动一个字段');
      }
    },
    // 自定义导出字段
    changeExportField(value, direction) {
      if (direction === 'left') {
        this.dataExportForm.checkedFields = [];
      }
    },

    exportCheckedFields(val) {
      this.dataExportForm.checkedFields = val;
    },
    // 确认导出
    comfirmExportData(condParams) {
      const params = {
        condition1: {
          conditions: this.searchMainDataOption(condParams),
          main_data: this.activeTab === '0' ? 't_hco' : 't_hcp',
          fields: this.comfirmExportFields(),
        },
        offset: 0,
        limit: this.dataExportForm.quantity,
      };
      console.log('导出入参', params);
      apis.CreateDataExportTask(params).then(res => {
        console.log('导出的数据', res);
        if (res && res.task_id) {
          this.$message.success('导出任务已生成，请到导入导出任务查看进度');
          this.handleCloseDialog();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './advance-search.scss';
</style>
