export default [
  // {
  //   title: '系统ID',
  //   width: 310,
  //   property: 'id',
  //   search: 'input',
  //   sort: false,
  //   edit: false,
  // },
  {
    title: '姓名',
    'min-width': 200,
    property: 'name',
    search: 'input',
    sort: false,
    edit: false,
    fixed: true,
  },
  {
    title: '姓名拼音',
    width: 200,
    property: 'pinyin',
    search: 'input',
    sort: false,
    edit: true,
  },
  {
    title: '性别',
    width: 80,
    property: 'gender',
    search: 'select',
    sort: false,
    edit: true,
  },
  {
    title: '所属机构名称',
    width: 300,
    property: 'hco_name',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: 'HCP类型',
    width: 120,
    property: 'hcp_type',
    search: 'select',
    sort: false,
    edit: true,
  },
  {
    title: 'HCP状态',
    width: 120,
    property: 'hcp_status',
    search: 'select',
    sort: false,
    edit: true,
  },
  {
    title: '挂牌科室',
    width: 150,
    property: 'listed_dept_name',
    search: 'input',
    sort: false,
    edit: true,
  },
  {
    title: '标准科室',
    width: 200,
    property: 'standard_dept_name',
    search: 'cascader',
    sort: false,
    edit: true,
  },
  {
    title: '学术头衔',
    width: 150,
    property: 'academic_title',
    search: 'select',
    sort: false,
    edit: true,
  },
  {
    title: '专业职称',
    width: 150,
    property: 'professional_title',
    search: 'select',
    sort: false,
    edit: true,
  },
  {
    title: '行政职务',
    width: 150,
    property: 'administrative_title',
    search: 'select',
    sort: false,
    edit: true,
  },
  {
    title: '执业许可证编码',
    property: 'nhc_license_number',
    width: 220,
    edit: false,
    search: 'input',
  },
  {
    title: '医师级别',
    width: 150,
    property: 'nhc_professional_level',
    search: 'input',
    sort: false,
    edit: true,
  },
  {
    title: '执业类别',
    width: 120,
    property: 'nhc_professional_type',
    search: 'input',
    sort: false,
    edit: true,
  },
  {
    title: '执业地点',
    width: 120,
    property: 'nhc_location',
    search: 'input',
    sort: false,
    edit: true,
  },
  {
    title: '执业范围',
    width: 120,
    property: 'nhc_scope',
    search: 'input',
    sort: false,
    edit: true,
  },
  {
    title: '证书颁发日期',
    width: 150,
    property: 'nhc_issue_date',
    search: 'date',
    sort: true,
    edit: false,
    format: true,
  },
  {
    title: '是否存疑',
    width: 100,
    property: 'in_doubt',
    search: 'select',
    sort: false,
    edit: false,
  },
  {
    title: '数据来源',
    width: 150,
    property: 'source',
    search: 'select',
    sort: false,
    edit: false,
  },
  {
    title: '质量负责人',
    width: 120,
    property: 'qa_persons',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '创建人',
    width: 120,
    property: 'create_user',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '创建时间',
    width: 180,
    property: 'create_time',
    search: 'date',
    sort: true,
    edit: false,
    format: true,
  },
  {
    title: '更新人',
    width: 120,
    property: 'update_user',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '更新时间',
    width: 180,
    property: 'update_time',
    search: 'date',
    sort: true,
    edit: false,
    format: true,
  },
];
