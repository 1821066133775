export default [
  {
    title: 'SKU编码',
    width: 200,
    property: 'sku_code',
    search: 'input',
    sort: false,
    edit: false,
    default: false,
  },
  {
    title: 'SKU名称',
    width: 200,
    property: 'sku_name',
    search: 'input',
    sort: false,
    edit: false,
    fixed: true,
    default: true,
  },
  {
    title: 'SKU英文名称',
    width: 200,
    property: 'sku_eng_name',
    search: 'input',
    sort: false,
    edit: false,
    default: false,
  },
  {
    title: '所属产品名称',
    width: 200,
    property: 'sku_product_name',
    search: 'input',
    sort: false,
    edit: false,
    default: false,
  },
  {
    title: '剂型分类',
    width: 120,
    property: 'administration',
    search: 'select',
    sort: false,
    edit: true,
    default: true,
  },
  {
    title: '剂型',
    width: 120,
    property: 'prepared_form',
    search: 'select',
    sort: false,
    edit: true,
    default: true,
  },
  {
    title: '单位',
    width: 120,
    property: 'unit',
    search: 'select',
    sort: false,
    edit: true,
    default: true,
  },
  {
    title: '大中包装规格',
    width: 120,
    property: 'big_package',
    search: 'input',
    sort: false,
    edit: true,
    default: false,
  },
  {
    title: '包装规格',
    width: 120,
    property: 'package',
    search: 'input',
    sort: false,
    edit: true,
    default: false,
  },
  {
    title: '规格',
    width: 120,
    property: 'specification',
    search: 'input',
    sort: false,
    edit: true,
    default: true,
  },
  {
    title: '成本价',
    width: 120,
    property: 'cost_price',
    search: 'number',
    sort: false,
    edit: true,
    default: false,
  },
  {
    title: '售价',
    width: 120,
    property: 'sales_price',
    search: 'number',
    sort: false,
    edit: true,
    default: false,
  },
  {
    title: '销售状态',
    width: 120,
    property: 'sales_status',
    search: 'select',
    sort: false,
    edit: true,
    default: true,
  },
  {
    title: '是否医保',
    width: 120,
    property: 'is_medical_insurance',
    search: 'select',
    sort: false,
    edit: true,
    default: true,
  },
  {
    title: '医保相关信息',
    width: 200,
    property: 'medical_insurance_info',
    search: 'input',
    sort: false,
    edit: true,
    default: false,
  },
  {
    title: '上市批准文号/注册证号',
    width: 200,
    property: 'approval_number',
    search: 'input',
    sort: false,
    edit: true,
    default: true,
  },
  {
    title: '药品本位码',
    width: 200,
    property: 'standard_code',
    search: 'input',
    sort: false,
    edit: true,
    default: false,
  },
  {
    title: '创建人',
    width: 200,
    property: 'create_user',
    search: 'input',
    sort: false,
    edit: false,
    default: true,
  },
  {
    title: '创建时间',
    width: 200,
    property: 'create_time',
    search: 'date',
    sort: true,
    edit: false,
    default: true,
    format: true,
  },
  {
    title: '更新人',
    width: 200,
    property: 'update_user',
    search: 'input',
    sort: false,
    edit: false,
    default: true,
  },
  {
    title: '更新时间',
    width: 200,
    property: 'update_time',
    search: 'date',
    sort: true,
    edit: false,
    default: true,
    format: true,
  },
  {
    title: '版本',
    width: 200,
    property: 'record_version',
    search: 'input',
    sort: false,
    edit: false,
    default: false,
  },
  {
    title: '数据来源',
    width: 200,
    property: 'source',
    search: 'select',
    sort: false,
    edit: false,
    default: false,
  },
  {
    title: '备注评论',
    width: 200,
    property: 'comment',
    search: 'input',
    sort: false,
    edit: true,
    default: false,
  },
  {
    title: '是否存疑',
    width: 200,
    property: 'in_doubt',
    search: 'select',
    sort: false,
    edit: false,
    default: false,
  },
  {
    title: '存疑原因',
    width: 200,
    property: 'doubt_reason',
    search: 'input',
    sort: false,
    edit: false,
    default: false,
  },
  {
    title: '质量负责人',
    width: 200,
    property: 'qa_persons',
    search: 'input',
    sort: false,
    edit: false,
    default: false,
  },
];
